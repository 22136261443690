<template>
	<div>
		<div class="manufactorbody">
			<div class="button" v-if="roleId != 1">
				<el-button type="primary" icon="el-icon-plus" @click="$router.push({ path: 'addmanufactor'})">新增厂家
				</el-button>
			</div>
			<div class="search">
				<el-input clearable @clear="inputclear" placeholder="请输入厂家名称" v-model="inputkeyword"
					class="input-with-select">
					<el-button slot="append" icon="el-icon-search" @click="keywordclick">点击搜索</el-button>
				</el-input>
			</div>
			<el-table v-loading="loadings" ref="multipleTable" :data="manufactordata" tooltip-effect="dark"
				style="width: 100%">
				<el-table-column type="index" label="序号">
				</el-table-column>
				<el-table-column type="expand">
					<template slot-scope="props">
						<div class="infos"><span>营业范围：</span>
							<span>{{props.row.ftyBusiness ? props.row.ftyBusiness: '无'}}</span>
						</div>
						<div class="infos"><span>公司官网地址：</span><span>{{props.row.ftyUrl ? props.row.ftyUrl: '无'}}</span>
						</div>
						<div class="infos">
							<span>公司简介：</span><span>{{props.row.ftyContent ? props.row.ftyContent: '无'}}</span>
						</div>
						<div class="infos"><span>公司logo：</span>
							<span v-if="props.row.ftyLogo" class="look"
								@click="zuopinInfo = props.row.ftyLogo.split(','),editshow = true">点击查看</span>
							<span v-else>无</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column width="100" prop="id" label="厂家ID">
				</el-table-column>
				<el-table-column prop="ftyName" show-overflow-tooltip label="厂家名称">
				</el-table-column>
				<el-table-column prop="ftyAddress" show-overflow-tooltip label="厂址">
				</el-table-column>
				<el-table-column prop="ftyTel" show-overflow-tooltip label="电话">
				</el-table-column>
				<el-table-column prop="ftyLegalUser" show-overflow-tooltip label="法人姓名">
				</el-table-column>
				<el-table-column show-overflow-tooltip label="联系电话">
					<template slot-scope="props">
						{{props.row.ftyFiled4 ? props.row.ftyFiled4: '无'}}
					</template>
				</el-table-column>
				<el-table-column show-overflow-tooltip label="产品类型">
					<template slot-scope="props">
						{{props.row.ftyFiled1 ? props.row.ftyFiled1: '无'}}
					</template>
				</el-table-column>
				<el-table-column prop="" show-overflow-tooltip label="营业执照">
					<template slot-scope="props">
						<span v-if="props.row.ftyCertificate" class="look"
							@click="zuopinInfo = props.row.ftyCertificate.split(','),editshow = true">点击查看</span>
						<span v-else>无</span>
					</template>
				</el-table-column>
				<el-table-column prop="" show-overflow-tooltip label="合格证书">
					<template slot-scope="props">
						<span v-if="props.row.ftyQualified" class="look"
							@click="zuopinInfo = props.row.ftyQualified.split(','),editshow = true">点击查看</span>
						<span v-else>无</span>
					</template>
				</el-table-column>
				<el-table-column prop="" show-overflow-tooltip label="操作">
					<template slot-scope="props">
						<el-button :disabled="userId == props.row.ftyFiled3 ? false : true" size="mini" type="primary"
							icon="el-icon-edit" @click="$router.push({path:'addmanufactor',query:{id:props.row.id}})">
						</el-button>
						<el-button :disabled="userId == props.row.ftyFiled3 ? false : true" size="mini" type="primary"
							icon="el-icon-delete" @click="manufactordelete(props.row.id)">
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-dialog :close-on-click-modal="false" :visible.sync="editshow" width="60%">
			<viewer :images="zuopinInfo" :z-index="99999">
				<el-carousel class="imgbanner" trigger="click" height="400px" :autoplay="false" arrow="always">
					<el-carousel-item v-for="(item,index) in zuopinInfo" :key="'pl'+index">
						<span class="imgbox">
							<img :src="item" alt="">
						</span>
					</el-carousel-item>
				</el-carousel>
			</viewer>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				userId: sessionStorage.getItem('userId'),
				roleId: sessionStorage.getItem('roleId'),
				loadings: false,
				editshow: false,
				manufactordata: '',
				inputkeyword: '',
				manufactordata: [],
				zuopinInfo: []
			}
		},
		created() {
			this.getAllFactory()
		},
		mounted() {},
		methods: {
			//获取厂家
			async getAllFactory() {
				this.manufactordata = []
				this.loadings = true
				await this.$get('programme/getAllFactory').then((res) => {
					this.manufactordata = res
				})
				this.$nextTick(() => {
					this.loadings = false
				})
			},
			//删除厂家
			manufactordelete(id) {
				this.$confirm('此操作将删除该厂家, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					beforeClose(action, instance, done) {
						if (action == "confirm") {
							instance.$refs.confirm.$el.onclick = function(e) {
								e = e || window.event
								console.log(e.detail)
								if (e.detail != 0) {
									done()
								}
							}
						} else {
							done()
						}
					}
				}).then(() => {
					this.$post("programme/deleteFactoryInfo", {
						id: id,
						createId: this.userId
					}).then((res) => {
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
						this.getAllFactory()
					})
				}).catch(() => {

				});
			},
			//清楚搜索
			inputclear() {
				this.inputkeyword = ""
				this.getAllFactory()
			},
			//查找厂家
			keywordclick() {
				if (this.inputkeyword) {
					this.manufactordata = []
					this.loadings = true
					this.$post("programme/queryFactoryInfo", {
						factoryName: this.inputkeyword
					}).then((res) => {
						this.manufactordata = res
					})
				} else {
					this.getAllFactory()
				}
				this.$nextTick(() => {
					this.loadings = false
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.imgbox {
		position: relative;
		display: block;
		width: 100%;
		height: 100%;

		>img {
			position: absolute;
			max-width: 100%;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}

	.infos {
		margin-bottom: 20px;

		>span {
			font-size: 14px;
			color: #999;
		}

		>:first-child {
			font-size: 16px;
			color: #666;
		}
	}

	.manufactorbody {
		.search {
			width: 50%;
			margin: 20px 0 40px 0;
		}

		.look {
			cursor: pointer;
		}
	}

	// .imgbanner {
	// 	img {
	// 		max-width: 100%;
	// 		display: block;
	// 		margin: 0 auto;
	// 	}
	// }
</style>
